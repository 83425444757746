import AppHeader from '../../../commons/AppHeader';
import { AppLayout } from '../../../core/AppLayout';
import { useParams } from 'react-router';
import { makeStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import { AlertDialog } from '../../../commons/AlertDialog';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import SimpleButton from '../../../commons/SimpleButton';
import { RequestStatus } from '../../../../utils/RequestStatus';
import FormContainer from '../../../commons/FormContainer';
import { useForm } from '../../../../hooks/useForm';
import { AppTextField } from '../../../commons/AppTextField';
import { AppTypography, AppTypographyProps } from '../../../Typography/AppTypography';
import { PDLTypography } from '../../../Typography/PDLTypography';
import BikeService from '../../../../services/BikeService';
import AlertService from '../../../../services/AlertService';
import { Bike } from '../../../../external/pdl-common/model/Bike';
import { BikeStatus } from '../../../../model/BikeStatus';

export interface TieredPriceForm {
  dailyPrice: string;
  twoDayPrice: string;
  threePlusDayPrice: string;
}

export default function HostBikeEditTieredPrice() {
  const { externalId } = useParams();
  const classes = useStyles();

  const navigate = useNavigate();

  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.SUCCESS);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);

  const [bike, setBike] = useState<Bike>();
  const [form, validations, handleCustomChange, formIsValid, clearForm, setValidations, setForm] =
    useForm<TieredPriceForm>({ dailyPrice: '', twoDayPrice: '', threePlusDayPrice: '' });

  const twoDayPriceInput = useRef<{ validate: () => void }>(null);
  const threePlusDayPriceInput = useRef<{ validate: () => void }>(null);

  useEffect(() => {
    getBikeDetails();
  }, []);

  useEffect(() => {
    updatedValidations();
  }, [form.twoDayPrice, form.threePlusDayPrice]);

  const updatedValidations = () => {
    const isTwoDayPriceValid = twoDayPriceInput?.current?.validate();
    const isThreePlusDayPriceValid = threePlusDayPriceInput?.current?.validate();

    let updatedValidations = { ...validations };
    (updatedValidations as any).twoDayPrice = isTwoDayPriceValid;
    (updatedValidations as any).threePlusDayPrice = isThreePlusDayPriceValid;
    setValidations(updatedValidations);
  };

  const getBikeDetails = async () => {
    if (externalId) {
      setRequestStatus(RequestStatus.LOADING);

      (await BikeService.getByExternalId(externalId))
        .onSuccess((response) => {
          const bike = response.getContent();

          setBike(bike);

          form.dailyPrice = bike.customPrice?.toString() || bike.listPrice?.toString() || '';
          form.twoDayPrice = bike.twoDayPrice?.toString() ?? '';
          form.threePlusDayPrice = bike.threePlusDayPrice?.toString() ?? '';

          setRequestStatus(RequestStatus.SUCCESS);
        })
        .onError((response) => {
          AlertService.showSnackCustomError(response.getContent());
          setRequestStatus(RequestStatus.ERROR);
        });
    }
  };

  const submitTieredPrice = async () => {
    if (externalId) {
      (await BikeService.updateTieredPrice(externalId, form))
        .onSuccess((response) => {
          AlertService.showSuccessMessage('Tiered price updated successfully');
          setRequestStatus(RequestStatus.SUCCESS);
        })
        .onError((response) => {
          AlertService.showSnackCustomError(response);
          setRequestStatus(RequestStatus.ERROR);
        });
    }
  };

  const handleInputChange = (inputName: string, inputValue: string, isValid: boolean) => {
    setIsChanged(true);
    handleCustomChange(inputName, inputValue, isValid);
  };

  const isSubmitBtnDisable = (): boolean => {
    const admitedStatuses = [BikeStatus.ACTIVE, BikeStatus.APPROVED, BikeStatus.PENDING];

    return (
      requestStatus === RequestStatus.LOADING ||
      !admitedStatuses.includes(bike?.status!) ||
      !isChanged ||
      !formIsValid()
    );
  };

  const renderContent = () => {
    return (
      <>
        <FormContainer
          divider={false}
          children={
            <>
              <Box className={classes.headingContainer}>
                <AppTypography
                  type={PDLTypography.mediumHeading}
                  children={'Set the bike price'}
                />
              </Box>
              <AppTypography
                type={PDLTypography.paragraph}
                children={
                  'Update the daily price and/or the tiered price for reservations of more than one day.'
                }
              />

              <AppTextField
                name={'dailyPrice'}
                label={'Daily price'}
                value={form.dailyPrice}
                labelProps={labelProps}
                onInputChange={handleInputChange}
                containerClasses={classes.textFieldContainer}
                realTimeValidation
                restrict={'onlyNumbersAndDots'}
              />

              <AppTextField
                ref={twoDayPriceInput}
                name={'twoDayPrice'}
                label={'2 day price'}
                value={form.twoDayPrice}
                labelProps={labelProps}
                onInputChange={handleInputChange}
                containerClasses={classes.textFieldContainer}
                helperText={'Set the custom price per day for reservation of two days'}
                realTimeValidation
                restrict={'onlyNumbersAndDots'}
                customValidation={(value) => {
                  if (
                    (form.threePlusDayPrice !== '' && value === '') ||
                    (form.threePlusDayPrice === '' && value !== '')
                  ) {
                    return {
                      showError: false,
                      isValid: false,
                      message: 'Both fields should be filled or leave empty',
                    };
                  }
                  return { showError: false, isValid: true, message: '' };
                }}
              />
              <AppTextField
                ref={threePlusDayPriceInput}
                name={'threePlusDayPrice'}
                label={'3+ day price'}
                value={form.threePlusDayPrice}
                labelProps={labelProps}
                onInputChange={handleCustomChange}
                containerClasses={classes.textFieldContainer}
                helperText={
                  'Set the custom price per day for reservations of three or more than three days'
                }
                realTimeValidation
                restrict={'onlyNumbersAndDots'}
                customValidation={(value) => {
                  if (
                    (form.twoDayPrice !== '' && value === '') ||
                    (form.twoDayPrice === '' && value !== '')
                  ) {
                    return {
                      showError: true,
                      isValid: false,
                      message: 'Both fields should be filled or leave empty',
                    };
                  }
                  return { showError: false, isValid: true, message: '' };
                }}
              />
            </>
          }
        />

        <Grid className={classes.button}>
          <SimpleButton
            disabled={isSubmitBtnDisable()}
            onClick={() => submitTieredPrice()}
            children={'Save price'}
            isLoading={requestStatus === RequestStatus.LOADING}
          />
        </Grid>

        <AlertDialog
          title="Leave edit"
          content="Are you sure to leave edit? Your changes will be discarted"
          open={openAlertDialog}
          onConfirm={() => {
            setOpenAlertDialog(false);
            navigate(-1);
          }}
          onCancel={() => setOpenAlertDialog(false)}
        />
      </>
    );
  };

  return (
    <AppLayout
      header={() => <AppHeader content={'Bike price'} onBack={() => navigate(-1)} />}
      content={() => renderContent()}
    />
  );
}

const labelProps: AppTypographyProps = {
  type: PDLTypography.subHeading,
  customStyles: {
    marginBottom: '0.7em',
    marginTop: '0.7em',
  },
};

const useStyles = makeStyles({
  headingContainer: {
    marginTop: '2em',
    marginBottom: '1em',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80vw',
    marginLeft: '10vw',
    marginTop: '1.5em',
    marginBottom: '1.5em',
  },
  textFieldContainer: {
    marginTop: '0.85em',
  },
});
